<template>
    <div class="flex flex-col gap-3">
        <div class="flex flex-row items-center gap-2">
            <div class="flex flex-col md:flex-row items-stretch md:items-center flex-wrap gap-2 text-sm font-medium w-full">
                <button
                    :disabled="disabled"
                    class="flex items-center gap-4 border-[#E8E8E8] disabled:opacity-75 transition-opacity border-2 rounded-xl leading-relaxed px-6 py-4 md:py-2 grow min-w-56"
                    type="button"
                    title="Edit date"
                    @click="toggleCollapse"
                >
                    <i class="bi bi-calendar text-xl mr-2"></i>
                    <span>{{ formattedDate.weekday }}, {{ formattedDate.month }} {{ formattedDate.day }}</span>
                    <i class="inline md:hidden bi bi-pen text-lg ml-auto"></i>
                </button>
                <button
                    :disabled="disabled"
                    class="flex items-center gap-4 border-[#E8E8E8] disabled:opacity-75 transition-opacity border-2 rounded-xl leading-relaxed px-6 py-4 md:py-2 grow min-w-40"
                    type="button"
                    title="Edit time"
                    @click="toggleCollapse"
                >
                    <i class="bi bi-clock text-xl"></i>
                    {{ formattedDate.time }}
                    <i class="inline md:hidden bi bi-pen text-lg ml-auto"></i>
                </button>
                <button
                    :disabled="disabled"
                    v-if="collapsed"
                    title="Edit date"
                    type="button"
                    class="disabled:opacity-75 [&:not(:disabled)]:hover:text-valence-grey-800/75 transition-opacity hidden md:inline grow-0"
                    @click="toggleCollapse"
                >
                    <span role="presentation" class="bi bi-pen text-lg"></span>
                </button>
            </div>
        </div>
        <div v-if="!collapsed" class="mx-auto w-5/6">
            <DatePicker
                ref="datepicker"
                :model-value="date"
                mode="dateTime"
                :rules="pickerRules"
                view="monthly"
                expanded
                borderless
                :min-date="new Date()"
                color="valence-blue"
                :attributes="attributes"
                @update:model-value="onDateUpdate"
            />
        </div>
        <label :class="disabled ? '' : 'cursor-pointer'" class="relative flex justify-between items-center border-2 rounded-3xl border-[#E8E8E8] py-4 px-6 max-w-[440px]">
            <div class="text-left">
                <div class="ml-3 text-sm font-semibold">Weekly check-ins?</div>
                <div class="ml-3 text-sm font-medium text-[#8C8C8C]">Check-in with me on {{ formattedDate.weekday }}</div>
            </div>
            <div class="relative">
                <input :disabled="disabled" id="prompt-text" type="checkbox" class="sr-only peer" :checked="recurring" @change="onRecurringUpdate" />
                <div
                    class="w-11 h-6 bg-neutral-400 peer-focus:outline-noneer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-[#31c859] peer-disabled:bg-neutral-300"
                ></div>
            </div>
        </label>
    </div>
</template>

<script setup>
import "v-calendar/style.css";
import { DateTime } from "luxon";
import { DatePicker } from "v-calendar";
import { computed, ref, useTemplateRef } from "vue";

const { isCollapsed, date, disabled, recurring, coachFollowupEvents } = defineProps({
    date: {
        type: Object,
        required: true,
        default: new Date(Date.now()),
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    recurring: {
        type: Boolean,
        required: true,
        default: true,
    },
    // used to set initial collapsed state
    isCollapsed: {
        type: Boolean,
        default: true,
    },
    // used to populate calendar with existing events
    coachFollowupEvents: {
        type: Array,
        default: () => [],
    },
});
const emit = defineEmits(["dateUpdate", "recurringUpdate"]);

const collapsed = ref(isCollapsed);
const attributes = computed(() => [
    {
        key: "today",
        highlight: true,
        dates: coachFollowupEvents.map(({ event_at_confirmed }) => new Date(event_at_confirmed)),
    },
]);

const pickerRules = ref({
    minutes: [0, 15, 30, 45],
});

const luxonDate = () => DateTime.fromJSDate(date);

const formattedDate = computed(() => {
    const date = luxonDate();
    return {
        day: date.day,
        month: date.monthShort,
        weekday: date.weekdayLong,
        time: date.toLocaleString(DateTime.TIME_SIMPLE),
    };
});

const toggleCollapse = () => {
    collapsed.value = !collapsed.value;
};

const datepicker = useTemplateRef("datepicker");
const onDateUpdate = (newValue) => {
    // This is here to fix a bug in v-calendar where changing the time doesn't keep the date.
    if (newValue) {
        setTimeout(() => {
            datepicker.value.move(new Date(newValue));
            emit("dateUpdate", newValue);
        }, 20);
    }
};

const onRecurringUpdate = () => {
    emit("recurringUpdate", !recurring);
};
</script>

<style type="postcss">
.vc-expanded {
    background: transparent;
}

.vc-header,
.vc-weeks,
.vc-time-picker {
    @apply px-0;
}

.vc-title {
    @apply text-base;
}

.vc-valence-blue {
    --vc-accent-600: #555ba2;
}
</style>
